.home-page {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 184px 40px;
  box-sizing: border-box;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    padding: 50px 20px 0 20px;
  }

  .pcbg {
    display: block;
    width: calc(100% - 510px);

    @media screen and (min-width: 1620px) {
      width: auto;
      height: 100%;
    }
    @media screen and (max-width: 1080px) {
      display: none;
    }
  }
  .mebg {
    width: 100vw;
    margin-top: 15px;
    display: none;
    @media screen and (max-width: 1080px) {
      display: block;
    }
  }
  .desc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: 48px;
    font-weight: 800;
    color: #050505;
    text-align: center;
    font-style: italic;
    @media screen and (max-width: 1080px) {
      font-size: 28px;
    }
  }
  .content {
    width: 540px;
    margin-top: 20px;
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 1.5;
    color: #050505;
    text-align: center;
    @media screen and (max-width: 1080px) {
      width: 80%;
      font-size: 15px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .button-container {
    width: 335px;
    height: 64px;
    border-radius: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    padding: 0 20px 0 12px;
    box-sizing: border-box;
    cursor: pointer;
    @media screen and (max-width: 1080px) {
      width: 100%;
    }

    .icon-l {
      width: 40px;
    }
    p {
      flex: 1;
      margin: 0 10px;
      font-size: 13px;
      text-align: left;
    }
    .icon-r {
      width: 24px;
    }
  }
  .black-button {
    background-color: #050505;

    p {
      color: white;
    }
  }
  .white-button {
    background-color: white;
    border: 1px solid #E6E6E6;

    p {
      color: #050505;
    }
  }
}